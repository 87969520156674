<template>
  <div class="tw-m-7">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">
          Annotations
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="ids"
        :pagination.sync="pagination"
        :total-items="pagination.totalItems"
        :rows-per-page-items="[20, 50, 100]"
        disable-page-reset
        :loading="isLoading || isError"
        class="overview-datatable elevation-1"
      >
        <v-progress-linear
          slot="progress"
          :color="isError ? 'error' : 'accent'"
          indeterminate
          :height="3"
        />
        <article-annotation-table-row
          slot="items"
          slot-scope="props"
          :annotation-id="props.item"
        />
      </v-data-table>
    </v-card>
  </div>
</template>
<script type="text/babel">
import {
  IDS as GETTER_IDS,
  IS_ERROR as GETTER_IS_ERROR,
  IS_LOADING as GETTER_IS_LOADING,
  PAGINATION as GETTER_PAGINATION
} from "../../store/templates/table/getter-types";
import { SET_PAGINATION as DISPATCH_SET_PAGINATION } from "../../store/templates/table/action-types";
import ArticleAnnotationTableRow from "./ArticleAnnotationTableRow";

export default {
  name: "article-annotations-dashboard",
  components: {
    ArticleAnnotationTableRow
  },
  data() {
    return {
      headers: [
        { text: "date", value: "date", align: "left", width: "140px" },
        {
          text: "Type",
          value: "type",
          sortable: false,
          align: "left",
          width: "60px"
        },
        {
          text: "Sub types",
          value: "type",
          sortable: false,
          align: "left",
          width: "60px"
        },
        {
          text: "Author",
          value: "author",
          sortable: false,
          align: "left",
          width: "120px"
        },
        { text: "annotation", value: "body", sortable: false, align: "center" },
        {
          text: "img",
          value: "files",
          sortable: false,
          align: "center",
          width: "240px"
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          class: "v-table-th--icon",
          rolesOrPermissions: "annotations_view"
        }
      ]
    };
  },
  computed: {
    pagination: {
      get() {
        return this.$store.getters[`article/annotations/${GETTER_PAGINATION}`];
      },
      set(pagination) {
        this.$store.dispatch(`article/annotations/${DISPATCH_SET_PAGINATION}`, {
          pagination
        });
      }
    },
    isLoading() {
      return this.$store.getters[`article/annotations/${GETTER_IS_LOADING}`];
    },
    isError() {
      return this.$store.getters[`article/annotations/${GETTER_IS_ERROR}`];
    },
    ids() {
      return this.$store.getters[`article/annotations/${GETTER_IDS}`];
    }
  }
};
</script>
