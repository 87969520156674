<template>
  <v-card>
    <div style="height: 200px">
      <vue-apex-charts
        ref="articleTotalConversionsChart"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout
      justify-space-between
      style="padding-left: 20px; padding-right: 20px"
    >
      <v-flex xs3>
        Average conversions: <br />
        <b style="font-size: 18px;">{{
          round(averageData.totalConversions, 0)
        }}</b>
      </v-flex>
      <v-flex xs6 style="text-align: end; color: gray;">
        Average last year: <br />
        <b style="font-size: 18px;">{{
          round(lastYearAverageData.totalConversions, 0)
        }}</b>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil, cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "article-total-conversions-stats",
  components: {
    vueApexCharts
  },
  props: {
    stats: {
      type: Array,
      default: () => []
    },
    lastYearStats: {
      type: Array,
      default: () => []
    },
    averageData: {
      type: Object,
      default: () => {}
    },
    lastYearAverageData: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: "Total Conversions"
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      series: [
        {
          name: "Total Conversions",
          type: "bar",
          data: []
        },
        {
          name: "Last Year Total Conversions",
          type: "bar",
          data: []
        }
      ]
    };
  },
  watch: {
    stats: {
      handler(newVal) {
        this.series.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.series[0].data.push([
            new Date(e.date).getTime(),
            e.totalConversions
          ]);
        });
        this.$refs.articleTotalConversionsChart.refresh();
      },
      deep: true
    },
    lastYearStats: {
      handler(newVal) {
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format(this.interval === "monthly" ? "YYYY-MM" : "YYYY-MM-DD");
          return data;
        });
        this.series[1].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.totalConversions
            ]);
          } else {
            this.series[1].data.push([data[0], 0.0]);
          }
        });
        this.$refs.articleTotalConversionsChart.refresh();
      },
      deep: true
    },
    annotations: {
      handler(newVal) {
        this.setAnnotations(newVal);
      },
      deep: true
    }
  },
  computed: {
    options() {
      return {
        chart: {
          id: "tc",
          group: "metrics",
          toolbar: {
            show: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
        grid: {
          show: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        title: {
          text: this.title,
          align: "center",
          margin: 10,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        colors: ["#FFCD00", "#2E6FB4"],
        xaxis: {
          type: "datetime"
        },
        yaxis: [
          {
            show: false,
            title: {
              text: "Conversions"
            },
            labels: {
              formatter: function(value) {
                return value;
              },
              minWidth: 0
            }
          }
        ]
      };
    }
  },
  methods: {
    round(rate, amount = 2) {
      return !isNil(rate) ? rate.toFixed(amount) : rate;
    }
  }
};
</script>
<style scoped></style>
