<template>
  <span class="log-add-button">
    <iframe
      v-if="researchLink"
      width="100%"
      height="800px"
      :src="iframeSrc"
    ></iframe>
    <v-btn
      v-if="researchLinkEditable"
      fab
      bottom
      right
      dark
      fixed
      :color="editing ? 'blue' : 'red'"
      @click="editing = !editing"
    >
      <v-icon>{{ editing ? "fas fa-chevron-left" : "edit" }}</v-icon>
    </v-btn>
  </span>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("article");

export default {
  name: "article-research",
  data: () => ({
    editing: false
  }),
  computed: {
    ...mapGetters(["researchLink", "researchLinkEditable"]),
    iframeSrc() {
      return this.editing ? this.researchLinkEditable : this.researchLink;
    }
  }
};
</script>
