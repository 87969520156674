<template>
  <table-overview
    v-if="!showChild"
    title="Articles"
    icon="fal fa-fw fa-globe"
    store="articles"
    :headers="hearders"
    searchable
    force-initial-sort="last_three_months_visitors"
    descending
  >
    <articles-table-row
      slot="table-row"
      slot-scope="{ item }"
      :article-id="item"
      :timeframe="timeframe"
    />
    <articles-table-filters
      slot="filters"
      store="articles"
      @set-timeframe="setTimeframe"
    />
  </table-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import ArticlesTableFilters from "@/components/article/ArticlesTableFilter";
import ArticlesTableRow from "@/components/article/ArticlesTableRow";
import articlesModule from "@/store/modules/articles";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "articles",
  articlesModule
);

export default {
  name: "articles-view",
  components: {
    TableOverview,
    ArticlesTableRow,
    ArticlesTableFilters
  },
  data: () => ({
    timeframe: "3M"
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    },
    hearders() {
      return [
        { text: "Url", value: "website.host" },
        { text: "Visitors", value: this.visitors },
        { text: "Bounce Rate", value: this.bounceRate },
        { text: "Time On Page", value: this.timeOnPage },
        { text: "Rejection Rate", value: this.rejectionRate },
        { text: "Total conversions", value: this.totalConversions },
        {
          text: "Total Conversion Rate",
          value: this.totalConversionRate
        },
        { text: "Direct conversions", sortable: false },
        {
          text: "Direct Conversion Rate",
          sortable: false
        },
        { text: "Landing conversions", sortable: false },
        {
          text: "Landing Conversion Rate",
          sortable: false
        },
        {
          text: "Valid",
          align: "center",
          sortable: false,
          class: "v-table-th--icon"
        },
        {
          text: "Sync",
          align: "center",
          sortable: false,
          class: "v-table-th--icon",
          rolesOrPermissions: "article_sync"
        },
        {
          text: "Details",
          align: "center",
          sortable: false,
          class: "v-table-th--icon",
          rolesOrPermissions: "article_view"
        }
      ];
    },
    visitors() {
      if (this.timeframe === "M") {
        return "last_month_visitors";
      } else if (this.timeframe === "W") {
        return "last_week_visitors";
      } else if (this.timeframe === "3M") {
        return "last_three_months_visitors";
      } else if (this.timeframe === "Y") {
        return "last_year_visitors";
      } else if (this.timeframe === "ALL") {
        return "all_time_visitors";
      } else {
        return null;
      }
    },
    bounceRate() {
      if (this.timeframe === "M") {
        return "last_month_bounce_rate";
      } else if (this.timeframe === "W") {
        return "last_week_bounce_rate";
      } else if (this.timeframe === "3M") {
        return "last_three_months_bounce_rate";
      } else if (this.timeframe === "Y") {
        return "last_year_bounce_rate";
      } else if (this.timeframe === "ALL") {
        return "all_time_bounce_rate";
      } else {
        return null;
      }
    },
    timeOnPage() {
      if (this.timeframe === "M") {
        return "last_month_time_on_page";
      } else if (this.timeframe === "W") {
        return "last_week_time_on_page";
      } else if (this.timeframe === "3M") {
        return "last_three_months_time_on_page";
      } else if (this.timeframe === "Y") {
        return "last_year_time_on_page";
      } else if (this.timeframe === "ALL") {
        return "all_time_time_on_page";
      } else {
        return null;
      }
    },
    rejectionRate() {
      if (this.timeframe === "M") {
        return "last_month_rejection_rate";
      } else if (this.timeframe === "W") {
        return "last_week_rejection_rate";
      } else if (this.timeframe === "3M") {
        return "last_three_months_rejection_rate";
      } else if (this.timeframe === "Y") {
        return "last_year_rejection_rate";
      } else if (this.timeframe === "ALL") {
        return "all_time_rejection_rate";
      } else {
        return null;
      }
    },
    totalConversions() {
      if (this.timeframe === "M") {
        return "last_month_total_conversions";
      } else if (this.timeframe === "W") {
        return "last_week_total_conversions";
      } else if (this.timeframe === "3M") {
        return "last_three_months_total_conversions";
      } else if (this.timeframe === "Y") {
        return "last_year_total_conversions";
      } else if (this.timeframe === "ALL") {
        return "all_time_total_conversions";
      } else {
        return null;
      }
    },
    totalConversionRate() {
      if (this.timeframe === "M") {
        return "last_month_total_conversion_rate";
      } else if (this.timeframe === "W") {
        return "last_week_total_conversion_rate";
      } else if (this.timeframe === "3M") {
        return "last_three_months_total_conversion_rate";
      } else if (this.timeframe === "Y") {
        return "last_year_total_conversion_rate";
      } else if (this.timeframe === "ALL") {
        return "all_time_total_conversion_rate";
      } else {
        return null;
      }
    }
  },
  methods: {
    setTimeframe(val) {
      this.$store.dispatch(`articles/setSort`, val);
      this.timeframe = val;
    }
  },
  ...overviewLifecycleMethods
};
</script>
