<template>
  <div class="tw-m-7 tw-mb-3">
    <v-layout row style="margin: 0">
      <v-flex xs6>
        <v-layout align-start justify-start row>
          <v-chip
            v-for="(tag, index) in tagNames"
            :key="index"
            color="primary"
            text-color="white"
            label
          >
            {{ tag }}
          </v-chip>
          <v-chip
            color="primary lighten-2"
            text-color="white"
            label
            @click="openDialog"
            ><v-icon>edit</v-icon></v-chip
          >
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout align-start justify-end>
          <v-chip
            v-for="(sibling, index) in articleSiblings"
            :key="index"
            color="accent"
            label
            ><router-link
              style="color: black; text-decoration: none;"
              :to="{
                name: 'articles.show',
                params: { articleId: sibling.article }
              }"
              target="_blank"
              >{{
                locale(website(sibling.websiteId).locales[0]).code
              }}</router-link
            >
          </v-chip>
        </v-layout>
      </v-flex>
    </v-layout>
    <article-tag-dialog :show-dialog="showDialog" @close="showDialog = false" />
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import ArticleTagDialog from "@/components/article/dashboard/ArticleTagDialog";

const { mapGetters, mapState } = createNamespacedHelpers("article");

export default {
  name: "article-tags",
  components: { ArticleTagDialog },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    ...mapGetters(["type", "tags", "articleSiblings"]),
    ...mapState(["tag"]),
    tagNames() {
      return this.tags.map(tagId =>
        this.tag[tagId] ? this.tag[tagId].name : null
      );
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    locale(id) {
      return this.$store.state.article.locale[id];
    },
    website(id) {
      return this.$store.state.article.website[id];
    }
  }
};
</script>
