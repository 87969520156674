<template>
  <v-card>
    <div style="height: 200px">
      <vue-apex-charts
        ref="articleDeviceConversionRateChart"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout>
      <v-flex xs4>
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average Desktop: <br />
            <b style="font-size: 18px;">{{ averageDesktopConversionRate }}%</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Last year average Desktop: <br />
            <b style="font-size: 18px;"
              >{{ lastYearAverageDesktopConversionRate }}%</b
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4 style="background-color: #ededed">
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average Phone: <br />
            <b style="font-size: 18px;">{{ averagePhoneConversionRate }}%</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Last year average Phone: <br />
            <b style="font-size: 18px;"
              >{{ lastYearAveragePhoneConversionRate }}%</b
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4>
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average Tablet: <br />
            <b style="font-size: 18px;">{{ averageTabletConversionRate }}%</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Last year average Tablet: <br />
            <b style="font-size: 18px;"
              >{{ lastYearAverageTabletConversionRate }}%</b
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";

import { isNil, cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "article-device-total-conversion-rate-stats",
  components: {
    vueApexCharts
  },
  props: {
    stats: {
      type: Array,
      default: () => []
    },
    lastYearStats: {
      type: Array,
      default: () => []
    },
    averageData: {
      type: Object,
      default: () => {}
    },
    lastYearAverageData: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: "Total conversion rate"
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      averageDesktopConversionRate: null,
      averagePhoneConversionRate: null,
      averageTabletConversionRate: null,
      lastYearAverageDesktopConversionRate: null,
      lastYearAveragePhoneConversionRate: null,
      lastYearAverageTabletConversionRate: null,
      series: [
        {
          name: "Desktop",
          type: "line",
          data: []
        },
        {
          name: "Last year Desktop",
          type: "line",
          data: []
        },
        {
          name: "Phone",
          type: "line",
          data: []
        },
        {
          name: "Last year Phone",
          type: "line",
          data: []
        },
        {
          name: "Tablet",
          type: "line",
          data: []
        },
        {
          name: "Last year Tablet",
          type: "line",
          data: []
        }
      ]
    };
  },
  computed: {
    options() {
      return {
        chart: {
          id: "tcr",
          group: "metrics",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: 2,
          dashArray: [0, 8, 0, 8, 0, 8]
        },
        title: {
          text: this.title,
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        colors: [
          "#0653A6",
          "#5D8BBD",
          "#0AAA00",
          "#87DA82",
          "#FFCD00",
          "#FFE98E"
        ],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            tickAmount: 5,
            labels: {
              formatter: value => {
                return `${this.round(value)}%`;
              },
              minWidth: 0
            },
            min: 0
          }
        ]
      };
    }
  },
  watch: {
    stats: {
      handler(newVal) {
        this.defineAverages(newVal);
        this.series.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.series[0].data.push([
            new Date(e.date).getTime(),
            e.desktopConversionRate
          ]);
          this.series[2].data.push([
            new Date(e.date).getTime(),
            e.phoneConversionRate
          ]);
          this.series[4].data.push([
            new Date(e.date).getTime(),
            e.tabletConversionRate
          ]);
        });
        this.$refs.articleDeviceConversionRateChart.refresh();
      },
      deep: true
    },
    lastYearStats: {
      handler(newVal) {
        this.defineLastYearAverages(newVal);
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format(this.interval === "monthly" ? "YYYY-MM" : "YYYY-MM-DD");
          return data;
        });
        this.series[1].data = [];
        this.series[3].data = [];
        this.series[5].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.desktopConversionRate
            ]);
            this.series[3].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.phoneConversionRate
            ]);
            this.series[5].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.tabletConversionRate
            ]);
          } else {
            this.series[1].data.push([data[0], 0.0]);
            this.series[3].data.push([data[0], 0.0]);
            this.series[5].data.push([data[0], 0.0]);
          }
        });
        setTimeout(this.disableLegends, 500);
        this.$refs.articleDeviceConversionRateChart.refresh();
      },
      deep: true
    }
  },
  methods: {
    round(rate) {
      return !isNil(rate) ? rate.toFixed(2) : rate;
    },
    defineAverages(series) {
      this.averageDesktopConversionRate = this.getAvg(
        series.map(serie => serie.desktopConversionRate)
      );
      this.averagePhoneConversionRate = this.getAvg(
        series.map(serie => serie.phoneConversionRate)
      );
      this.averageTabletConversionRate = this.getAvg(
        series.map(serie => serie.tabletConversionRate)
      );
    },
    defineLastYearAverages(series) {
      this.lastYearAverageDesktopConversionRate = this.getAvg(
        series.map(serie => serie.desktopConversionRate)
      );
      this.lastYearAveragePhoneConversionRate = this.getAvg(
        series.map(serie => serie.phoneConversionRate)
      );
      this.lastYearAverageTabletConversionRate = this.getAvg(
        series.map(serie => serie.tabletConversionRate)
      );
    },
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(2);
    },
    disableLegends() {
      const chart = this.$refs.articleDeviceConversionRateChart;
      if (!isNil(chart)) {
        chart.toggleSeries("Last year Desktop");
        chart.toggleSeries("Last year Phone");
        chart.toggleSeries("Last year Tablet");
      }
    }
  }
};
</script>
<style scoped>
.custom-tooltip {
  background-color: white;
  padding: 10px;
}
.toolbar-text-info {
  font-weight: bold;
}
.positive-text {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}
.negative-text {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
.last-year-stat {
  font-size: 11px;
  font-weight: 100;
  color: #878787;
}
</style>
