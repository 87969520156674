<template>
  <div>
    <transition-group name="fade" mode="out-in">
      <div v-show="!isLoadingStats" key="data">
        <v-layout>
          <v-flex class="mx-4" xs12>
            <article-total-conversion-rate-stats-with-y-axis
              :stats="stats"
              :last-year-stats="lastYearStats"
              :average-data="averageData"
              :last-year-average-data="lastYearAverageData"
              :interval="interval"
            />
            <template v-for="(siblingId, index) in selectedSiblings">
              <article-total-conversion-rate-stats-with-y-axis
                v-if="showSibling(siblingId)"
                :key="index"
                :title="
                  `${
                    siblingWebsite(articleSibling(siblingId).websiteId).domain
                  }${articleSibling(siblingId).path}`
                "
                :stats="siblingStats[siblingId].stats"
                :last-year-stats="siblingStats[siblingId].lastYearStats"
                :average-data="siblingStats[siblingId].averageData"
                :last-year-average-data="
                  siblingStats[siblingId].lastYearAverageData
                "
                :interval="interval"
              />
            </template>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex class="mx-4" xs12>
            <article-traffic-graph
              :stats="stats"
              :last-year-stats="lastYearStats"
              :average-data="averageData"
              :last-year-average-data="lastYearAverageData"
              :interval="interval"
            />
            <template v-for="(siblingId, index) in selectedSiblings">
              <article-traffic-graph
                v-if="showSibling(siblingId)"
                :key="index"
                :title="
                  `${
                    siblingWebsite(articleSibling(siblingId).websiteId).domain
                  }${articleSibling(siblingId).path}`
                "
                :interval="interval"
                :stats="siblingStats[siblingId].stats"
                :last-year-stats="siblingStats[siblingId].lastYearStats"
                :average-data="siblingStats[siblingId].averageData"
                :last-year-average-data="
                  siblingStats[siblingId].lastYearAverageData
                "
              />
            </template>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex class="mx-4" xs12>
            <article-total-conversions-stats
              :stats="stats"
              :last-year-stats="lastYearStats"
              :average-data="averageData"
              :last-year-average-data="lastYearAverageData"
              :interval="interval"
            />
            <template v-for="(siblingId, index) in selectedSiblings">
              <article-total-conversions-stats
                v-if="showSibling(siblingId)"
                :key="index"
                :title="
                  `${
                    siblingWebsite(articleSibling(siblingId).websiteId).domain
                  }${articleSibling(siblingId).path}`
                "
                :stats="siblingStats[siblingId].stats"
                :last-year-stats="siblingStats[siblingId].lastYearStats"
                :average-data="siblingStats[siblingId].averageData"
                :last-year-average-data="
                  siblingStats[siblingId].lastYearAverageData
                "
                :interval="interval"
              />
            </template>
          </v-flex>
        </v-layout>
        <v-flex xs4 class="mx-4">
          <between-date-input v-model="dateInput" />
        </v-flex>
        <v-layout row>
          <v-flex class="mx-4" xs4>
            <article-subject-map
              :selected-siblings="selectedSiblings"
              :date="dateInput"
            />
          </v-flex>
          <v-flex
            class="mx-4"
            v-for="(siblingId, index) in selectedSiblings"
            xs4
            :key="index"
          >
            <article-subject-map
              v-if="showSibling(siblingId)"
              :key="index"
              :title="
                `${siblingWebsite(articleSibling(siblingId).websiteId).domain}${
                  articleSibling(siblingId).path
                }`
              "
              :sibling="siblingId"
              :date="dateInput"
            />
          </v-flex>
        </v-layout>
      </div>
      <div
        key="loading"
        v-if="isLoadingStats"
        class="tw-grid tw-grid-cols-1 tw-col-span-full tw-content-end tw-justify-center tw-h-32 tw-m-6 tw-p-0"
      >
        <h2 class="tw-flex tw-justify-center tw-align-center loading-info">
          ...Statistics are loading
        </h2>
        <v-progress-linear
          class="tw-m-0"
          :indeterminate="true"
          color="secondary"
        />
      </div>
    </transition-group>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import Vue from "vue";
import moment from "moment";
import { isNil } from "lodash";
import ArticleTotalConversionsStats from "@/components/article/dashboard/stats/ArticleTotalConversions";
import api from "@/api";
import ArticleTrafficGraph from "@/components/article/dashboard/stats/ArticleTrafficGraph";
import ArticleTotalConversionRateStatsWithYAxis from "@/components/article/dashboard/stats/ArticleTotalConversionRateWithYaxis";
import ArticleSubjectMap from "../../components/article/dashboard/stats/ArticleSubjectMap";
import BetweenDateInput from "../../components/generic/input/dates/BetweenDateInput";

const { mapGetters } = createNamespacedHelpers("article");

const fetchCall = api.article.stats.fetchTimeSeries;
const fetchCallAverages = api.article.stats.fetchAverages;

export default {
  name: "article-total-stats-view",
  components: {
    BetweenDateInput,
    ArticleSubjectMap,
    ArticleTotalConversionRateStatsWithYAxis,
    ArticleTrafficGraph,
    ArticleTotalConversionsStats
  },
  props: {
    selectedSiblings: {
      type: Array,
      default: () => []
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      stats: [],
      lastYearStats: [],
      averageData: {},
      lastYearAverageData: {},
      siblingStats: [],
      dateInput: {},
      isLoadingStats: false
    };
  },
  computed: {
    ...mapGetters(["article", "type"]),
    annotations() {
      return this.article ? this.article.annotations : [];
    },
    now() {
      return moment().format("DD-MM-YYYY");
    },
    oneYearAgo() {
      return moment()
        .subtract(1, "years")
        .format("DD-MM-YYYY");
    },
    siblings() {
      return this.$store.getters["article/articleSiblings"];
    }
  },
  beforeMount() {
    const to = moment().format("YYYY-MM-DD");
    const from = moment()
      .subtract(1, "years")
      .startOf("month")
      .format("YYYY-MM-DD");

    const lastYearTo = moment()
      .subtract(1, "years")
      .endOf("month")
      .format("YYYY-MM-DD");
    const lastYearFrom = moment()
      .subtract(2, "years")
      .startOf("month")
      .format("YYYY-MM-DD");
    this.fetch(from, to, lastYearFrom, lastYearTo);
  },
  watch: {
    interval(newVal) {
      if (newVal === "monthly") {
        const to = moment().format("YYYY-MM-DD");
        const from = moment()
          .subtract(1, "years")
          .startOf("month")
          .format("YYYY-MM-DD");

        const lastYearTo = moment()
          .subtract(1, "years")
          .endOf("month")
          .format("YYYY-MM-DD");
        const lastYearFrom = moment()
          .subtract(2, "years")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.fetch(from, to, lastYearFrom, lastYearTo);
        if (this.selectedSiblings.length > 0) {
          this.fetchSiblingStats(
            this.selectedSiblings,
            from,
            to,
            lastYearFrom,
            lastYearTo
          );
        }
      } else {
        const to = moment().format("YYYY-MM-DD");
        const from = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");

        const lastYearTo = moment()
          .subtract(1, "years")
          .endOf("month")
          .format("YYYY-MM-DD");
        const lastYearFrom = moment()
          .subtract(1, "years")
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.fetch(from, to, lastYearFrom, lastYearTo);
        if (this.selectedSiblings.length > 0) {
          this.fetchSiblingStats(
            this.selectedSiblings,
            from,
            to,
            lastYearFrom,
            lastYearTo
          );
        }
      }
    },
    fab(val) {
      this.tooltips = false;
      this.tooltipsDisabled = false;
      val &&
        setTimeout(() => {
          this.tooltips = true;
          this.$nextTick(() => (this.tooltipsDisabled = true));
        }, 100);
    },
    selectedSiblings(newVal, oldVal) {
      let difference = newVal.filter(x => !oldVal.includes(x))[0];

      if (!isNil(difference)) {
        if (newVal === "monthly") {
          const to = moment().format("YYYY-MM-DD");
          const from = moment()
            .subtract(1, "years")
            .startOf("month")
            .format("YYYY-MM-DD");

          const lastYearTo = moment()
            .subtract(1, "years")
            .endOf("month")
            .format("YYYY-MM-DD");
          const lastYearFrom = moment()
            .subtract(2, "years")
            .startOf("month")
            .format("YYYY-MM-DD");
          if (this.selectedSiblings.length > 0) {
            this.fetchSiblingStats(
              this.selectedSiblings,
              from,
              to,
              lastYearFrom,
              lastYearTo
            );
          }
        } else {
          const to = moment().format("YYYY-MM-DD");
          const from = moment()
            .subtract(3, "months")
            .startOf("month")
            .format("YYYY-MM-DD");

          const lastYearTo = moment()
            .subtract(1, "years")
            .endOf("month")
            .format("YYYY-MM-DD");
          const lastYearFrom = moment()
            .subtract(1, "years")
            .subtract(3, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
          if (this.selectedSiblings.length > 0) {
            this.fetchSiblingStats(
              this.selectedSiblings,
              from,
              to,
              lastYearFrom,
              lastYearTo
            );
          }
        }
      }
    }
  },
  methods: {
    createAnnotationDialog() {
      this.$store.commit("article/openAnnotationDialog", true);
    },
    articleSibling(id) {
      return isNil(id) ? "" : this.$store.getters["article/sibling"](id);
    },
    siblingWebsite(id) {
      return this.$store.getters["article/siblingWebsite"](id);
    },
    async fetch(from, to, lastYearFrom, lastYearTo) {
      this.isLoadingStats = true;
      this.stats = await fetchCall(this.$store.state.article.model.id, {
        date: { from: from, to: to },
        interval: this.interval
      });
      this.lastYearStats = await fetchCall(this.$store.state.article.model.id, {
        date: { from: lastYearFrom, to: lastYearTo },
        interval: this.interval
      });
      this.averageData = await fetchCallAverages(
        this.$store.state.article.model.id,
        {
          date: { from: from, to: to },
          interval: this.interval
        }
      );
      this.lastYearAverageData = await fetchCallAverages(
        this.$store.state.article.model.id,
        {
          date: { from: lastYearFrom, to: lastYearTo },
          interval: this.interval
        }
      );
      this.isLoadingStats = false;
    },
    async fetchSiblingStats(articleId, from, to, lastYearFrom, lastYearTo) {
      this.isLoadingStats = true;
      Vue.set(this.siblingStats, articleId, {});
      Vue.set(this.siblingStats[articleId], "stats", []);
      Vue.set(this.siblingStats[articleId], "lastYearStats", []);
      Vue.set(this.siblingStats[articleId], "averageData", {});
      Vue.set(this.siblingStats[articleId], "lastYearAverageData", {});

      const stats = await fetchCall(articleId, {
        date: { from: from, to: to },
        interval: this.interval
      });
      const lastYearStats = await fetchCall(articleId, {
        date: { from: lastYearFrom, to: lastYearTo },
        interval: this.interval
      });
      const averageData = await fetchCallAverages(articleId, {
        date: { from: from, to: to },
        interval: this.interval
      });
      const lastYearAverageData = await fetchCallAverages(articleId, {
        date: { from: lastYearFrom, to: lastYearTo },
        interval: this.interval
      });
      Vue.set(this.siblingStats, articleId, {});
      Vue.set(this.siblingStats[articleId], "stats", stats);
      Vue.set(this.siblingStats[articleId], "lastYearStats", lastYearStats);
      Vue.set(this.siblingStats[articleId], "averageData", averageData);
      Vue.set(
        this.siblingStats[articleId],
        "lastYearAverageData",
        lastYearAverageData
      );
      this.isLoadingStats = false;
    },
    showSibling(id) {
      if (!isNil(this.siblingStats[id])) {
        return !isNil(this.siblingStats[id].stats);
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped>
.date {
  text-align: center;
  color: darkgray;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
