<template>
  <div>
    <v-container>
      <p class="info-text">
        <b>Enkel Landing conversions</b> = Deze pagina was de landingspagina
        (eerste pagina bezocht door de bezoeker) en heeft onrechtstreeks dus
        gezorgd voor een conversie.
      </p>
      <p class="info-text">
        <b>Enkel Direct conversion</b> = Deze pagina was de laatst bezochte
        pagina die uiteindelijk tot een conversie geleid heeft (op de pagina
        zelf of een eerstvolgende /offerte pagina).
      </p>
      <p class="info-text">
        <b>Combinatie van Landing en Direct</b> = Deze pagina was de eerst
        bezochte pagina en heeft rechtstreeks voor een conversie gezorgd op de
        pagina zelf of eerstvolgend op een /offerte pagina.
      </p>
    </v-container>
    <table-overview
      title="Submissions"
      icon="far fa-fw fa-globe"
      store="article/submissions"
      :headers="[
        { text: 'Completed at', value: 'completed_at' },
        { text: 'Name', value: 'full_name', sortable: false },
        { text: 'Conversion', sortable: false },
        { text: 'Website', value: 'website', sortable: false },
        { text: 'Subjects', value: 'subjects', sortable: false },
        { text: 'Leads', value: 'leads', sortable: false },
        { text: 'Status', value: 'id', sortable: false },
        {
          text: 'Details',
          align: 'center',
          sortable: false,
          class: 'v-table-th--icon',
          rolesOrPermissions: 'submission_view'
        }
      ]"
      force-initial-sort="completed_at"
      descending
    >
      <article-submission-table-row
        slot="table-row"
        slot-scope="{ item }"
        :submission-id="item"
        :article-id="id"
      />
      <article-submission-table-filter slot="filters" />
    </table-overview>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

import TableOverview from "@/components/generic/overview/TableOverview";
import ArticleSubmissionTableRow from "@/components/article/ArticleSubmissionTableRow";
import ArticleSubmissionTableFilter from "@/components/article/ArticleSubmissionTableFilter";

const { mapGetters } = createNamespacedHelpers("article");
const { mapGetters: mapSubmissionGetters } = createNamespacedHelpers(
  "article/submissions"
);

export default {
  name: "article-submissions-view",
  components: {
    ArticleSubmissionTableFilter,
    ArticleSubmissionTableRow,
    TableOverview
  },
  data: () => ({
    triggerGroupMatchData: undefined
  }),
  computed: {
    ...mapGetters(["id"]),
    ...mapSubmissionGetters([FIND_BY_ID])
  },
  methods: {
    submission(appliedId) {
      return this[FIND_BY_ID](appliedId).submission;
    }
  }
};
</script>
<style scoped>
.info-text {
  margin-bottom: 2px;
}
</style>
