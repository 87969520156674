import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
import { SET_IS_LOADING, SET_MODEL } from "../../templates/crud/mutation-types";

export default {
  ...crudActions(api.article, "article"),
  async addTags({ commit }, { articleId, tags }) {
    commit(SET_IS_LOADING, true);
    const { entities, result } = await api.article.addTags(articleId, tags);
    commit(SET_MODEL, { entities, id: result });
    commit(SET_IS_LOADING, false);
    return result;
  }
};
