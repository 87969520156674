import initialCrudState from "../../templates/crud/state";

const initialModel = () => ({
  id: null,
  website: null,
  path: null,
  title: null,
  description: null,
  remark: null,
  locales: [],
  subjects: [],
  isSyncing: false,
  isSynced: false,
  type: null,
  timeframeData: null,
  sibling: null,
  tags: [],
  researchLink: null,
  researchLinkEditable: null,
  isSearchable: null
});

const isAnnotationDialogOpen = false;
const annotationDialogId = null;

export default () => ({
  ...initialCrudState(initialModel),
  website: {},
  sibling: {},
  timeframeMetric: {},
  pageTypeMetric: {},
  tag: {},
  isAnnotationDialogOpen: isAnnotationDialogOpen,
  annotationDialogId: annotationDialogId,
  article: {},
  articleCluster: {},
  articleSibling: {},
  locale: {}
});
