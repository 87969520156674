var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('p',{staticClass:"info-text"},[_c('b',[_vm._v("Enkel Landing conversions")]),_vm._v(" = Deze pagina was de landingspagina (eerste pagina bezocht door de bezoeker) en heeft onrechtstreeks dus gezorgd voor een conversie. ")]),_c('p',{staticClass:"info-text"},[_c('b',[_vm._v("Enkel Direct conversion")]),_vm._v(" = Deze pagina was de laatst bezochte pagina die uiteindelijk tot een conversie geleid heeft (op de pagina zelf of een eerstvolgende /offerte pagina). ")]),_c('p',{staticClass:"info-text"},[_c('b',[_vm._v("Combinatie van Landing en Direct")]),_vm._v(" = Deze pagina was de eerst bezochte pagina en heeft rechtstreeks voor een conversie gezorgd op de pagina zelf of eerstvolgend op een /offerte pagina. ")])]),_c('table-overview',{attrs:{"title":"Submissions","icon":"far fa-fw fa-globe","store":"article/submissions","headers":[
      { text: 'Completed at', value: 'completed_at' },
      { text: 'Name', value: 'full_name', sortable: false },
      { text: 'Conversion', sortable: false },
      { text: 'Website', value: 'website', sortable: false },
      { text: 'Subjects', value: 'subjects', sortable: false },
      { text: 'Leads', value: 'leads', sortable: false },
      { text: 'Status', value: 'id', sortable: false },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'submission_view'
      }
    ],"force-initial-sort":"completed_at","descending":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('article-submission-table-row',{attrs:{"submission-id":item,"article-id":_vm.id}})}}])},[_c('article-submission-table-filter',{attrs:{"slot":"filters"},slot:"filters"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }