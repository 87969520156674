<template>
  <v-card>
    <div style="height: 200px">
      <vue-apex-charts
        ref="articleTrafficChart"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout>
      <v-flex xs4>
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average Visitors: <br />
            <b style="font-size: 18px;">{{
              round(averageData.uniquePageviews, 0)
            }}</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Average last year: <br />
            <b style="font-size: 18px;">{{
              round(lastYearAverageData.uniquePageviews, 0)
            }}</b>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4 style="background-color: #ededed">
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average landing entrances: <br />
            <b style="font-size: 18px;">{{
              round(averageData.uniqueLandingSessions, 0)
            }}</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Average last year: <br />
            <b style="font-size: 18px;">{{
              round(lastYearAverageData.uniqueLandingSessions, 0)
            }}</b>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4>
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average bounce rate: <br />
            <b style="font-size: 18px;">{{ round(averageData.bounceRate) }}%</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Average last year: <br />
            <b style="font-size: 18px;"
              >{{ round(lastYearAverageData.bounceRate) }}%</b
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil, cloneDeep, groupBy, uniq } from "lodash";
import moment from "moment";

export default {
  name: "article-traffic-graph",
  components: {
    vueApexCharts
  },
  props: {
    stats: {
      type: Array,
      default: () => []
    },
    lastYearStats: {
      type: Array,
      default: () => []
    },
    averageData: {
      type: Object,
      default: () => {}
    },
    lastYearAverageData: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: "Traffic"
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      series: [
        {
          name: "Visitors",
          type: "line",
          data: []
        },
        {
          name: "Last year Visitors",
          type: "line",
          data: []
        },
        {
          name: "Landing Entrances",
          type: "line",
          data: []
        },
        {
          name: "Last year Landing Entrances",
          type: "line",
          data: []
        },
        {
          name: "Bounce rate",
          type: "line",
          data: []
        },
        {
          name: "Last year Bounce rate",
          type: "line",
          data: []
        }
      ],
      chartAnnotations: { points: [] },
      clickableClasses: []
    };
  },
  watch: {
    stats: {
      handler(newVal) {
        this.series.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.series[0].data.push([
            new Date(e.date).getTime(),
            parseInt(e.uniquePageviews),
            e.date
          ]);
          this.series[2].data.push([
            new Date(e.date).getTime(),
            parseInt(e.uniqueLandingSessions)
          ]);
          this.series[4].data.push([new Date(e.date).getTime(), e.bounceRate]);
        });
        this.$refs.articleTrafficChart.refresh();
      },
      deep: true
    },
    lastYearStats: {
      handler(newVal) {
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format(this.interval === "monthly" ? "YYYY-MM" : "YYYY-MM-DD");
          return data;
        });
        this.series[1].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          const equivalentLastYearIndex = mappedVal.findIndex(
            lastYear => data[0] === new Date(lastYear.date).getTime()
          );
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.uniquePageviews,
              newVal[equivalentLastYearIndex].date
            ]);
            this.series[3].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.uniqueLandingSessions
            ]);
            this.series[5].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.bounceRate
            ]);
          } else {
            this.series[1].data.push([data[0], 0]);
            this.series[3].data.push([data[0], 0]);
            this.series[5].data.push([data[0], 0.0]);
          }
        });
        setTimeout(this.disableLegends, 500);

        this.$refs.articleTrafficChart.refresh();
        this.applyAnnotations();
      },
      deep: true
    }
  },
  computed: {
    annotations() {
      return this.$store.getters["website/annotations/entities"];
    },
    options() {
      return {
        chart: {
          id: "traffic",
          group: "metrics",
          height: "100%",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: 2,
          dashArray: [0, 8, 0, 8, 0, 8]
        },
        title: {
          text: this.title,
          align: "center",
          margin: 10,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        markers: {
          size: 3,
          hover: {
            size: 6
          }
        },
        colors: [
          "#0653A6",
          "#0653A6",
          "#8aacd1",
          "#8aacd1",
          "#FFCD00",
          "#FFCD00"
        ],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Visitors"
            }
          },
          {
            show: false,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Landing Visitors"
            }
          },
          {
            show: false,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Landing Visitors"
            }
          },
          {
            show: false,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Landing Visitors"
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          }
        ],
        annotations: this.chartAnnotations
      };
    }
  },
  methods: {
    round(rate, amount = 2) {
      return !isNil(rate) ? rate.toFixed(amount) : rate;
    },
    // disableLegends() {
    //   const chart = this.$refs.articleTrafficChart;
    //   if (!isNil(chart)) {
    //     chart.toggleSeries("Landing Entrances");
    //     chart.toggleSeries("Last year Landing Entrances");
    //   }
    // },
    applyAnnotations() {
      this.clickableClasses = [];
      this.chartAnnotations.points = [];
      if (this.interval === "monthly") {
        //group by month
        const groupedAnnotations = groupBy(this.annotations, function(
          annotation
        ) {
          return annotation.date.substring(0, annotation.date.length - 3);
        });
        // we check if there are any annotation that could be located in the timeframe of the chart
        Object.entries(groupedAnnotations).forEach(([key, value]) => {
          this.series.forEach(serie => {
            const location = serie.data.filter(data => data[2] === key);
            if (!isNil(location)) {
              if (!isNil(location[0])) {
                this.addPointAnnotation(location, value);
              }
            }
          });
        }, this);
      } else {
        //handle by day
        const groupedAnnotations = groupBy(this.annotations, function(
          annotation
        ) {
          return annotation.date;
        });
        Object.entries(groupedAnnotations).forEach(([key, value]) => {
          this.series.forEach(serie => {
            const location = serie.data.filter(data => data[2] === key);
            if (!isNil(location)) {
              if (!isNil(location[0])) {
                this.addPointAnnotation(location, value);
              }
            }
          });
        }, this);
      }
      // After creating the annotations we look them up and add a click event listener
      this.$refs.articleTrafficChart.refresh();
      // the chart and annotations have to be fully rendered so we can not execute this immediately after adding the annotations
      setTimeout(() => {
        this.applyClickHandlersToAnnotations();
      }, 2000);
    },
    applyClickHandlersToAnnotations() {
      this.clickableClasses = uniq(this.clickableClasses);
      this.clickableClasses.forEach(className => {
        const point = document.getElementsByClassName(className);
        point[0].addEventListener("mouseover", () => {
          this.hoverIn(className);
        });
        point[0].addEventListener("mouseout", () => {
          this.hoverOut(className);
        });
        point[0].addEventListener("click", () => {
          this.clicked(className);
        });
      });
    },
    addPointAnnotation(location, annotation) {
      const labels = annotation.map(
        annotation =>
          `${annotation.date}: ${annotation.subTypes
            .map(subType => subType.title)
            .join(", ")}`
      );

      const pointAnnotation = {
        x: location[0][0],
        y: location[0][1],
        label: {
          text: labels.join(" | "),
          offsetY: -1000,
          style: { cssClass: `label ${location[0][2]}-traffic` }
        },
        marker: { size: 6, cssClass: `point ${location[0][2]}-traffic` }
      };
      this.chartAnnotations.points.push(pointAnnotation);

      this.clickableClasses.push(`${location[0][2]}-traffic`);
    },
    hoverIn(className) {
      // eslint-disable-next-line no-unused-vars
      const point = document.getElementsByClassName(`point ${className}`)[0];
      point.setAttribute("r", 9);
    },
    // eslint-disable-next-line no-unused-vars
    hoverOut(className) {
      // eslint-disable-next-line no-unused-vars
      const point = document.getElementsByClassName(`point ${className}`)[0];
      point.setAttribute("r", 6);
    },
    clicked(className) {
      // if there is no group containing the annotation text we create one
      const group = document.getElementById(`group-${className}`);
      if (isNil(group)) {
        // get the text element from apexcharts and remove the Y offset
        const textSvg = document.getElementsByClassName(
          `label ${className}`
        )[0];
        textSvg.setAttribute("y", 0);
        // get the bounding box of the text element we use this to define the height of the background text
        const textSvgRect = textSvg.getBBox();
        // create the group element we use to wrap the text and bg rect in
        const group = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );
        group.setAttribute("id", `group-${className}`);

        // create the rect we will use as the bg for the text
        let rect = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );
        // prevent the annotation label to be out the screen when it is at the start of the chart
        const isAnnotationAtStart = textSvg.getAttribute("x") < 50;
        if (isAnnotationAtStart) {
          textSvg.setAttribute("text-anchor", "start");
        }
        const rectAttributes = {
          x: isAnnotationAtStart
            ? textSvg.getAttribute("x") - 5
            : textSvg.getAttribute("x") - textSvgRect.width / 2 - 5,
          y: textSvg.getAttribute("y") - 10 - 4,
          height: textSvgRect.height + 8,
          width: textSvgRect.width + 10
        };
        // use the text bounding box to define the size and position of our new rect.
        // the text is positioned in the center of our anchor so we divide the x choordinates in half
        // the +x is to get some padding around the text
        rect.setAttribute("x", rectAttributes.x);
        rect.setAttribute("y", rectAttributes.y);
        rect.setAttribute("width", rectAttributes.width);
        rect.setAttribute("height", rectAttributes.height);
        rect.setAttribute("fill", "white");
        rect.setAttribute("text-anchor", "middle");
        textSvg.parentNode.insertBefore(group, textSvg);
        group.appendChild(rect);
        group.appendChild(textSvg);
      } else {
        // move the group in and out of vision on clicks
        if (isNil(group.getAttribute("transform"))) {
          group.setAttribute("transform", "translate(0, 200)");
        } else {
          group.removeAttribute("transform");
        }
      }
    }
  }
};
</script>
<style scoped>
>>> .apexcharts-tooltip-z-group {
  display: none;
}
</style>
