<template>
  <v-dialog :key="showDialog" v-model="showDialog" max-width="800" lazy>
    <v-card color="white">
      <crud-header
        :title="title"
        type="Annotation"
        store="annotation"
        :deletable="deletable"
        @save-success="closeDialog"
        @delete-success="closeDialog"
      >
        <v-menu
          v-if="hasFiles"
          slot="toolbarActions"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          left
          offset-x
        >
          <v-btn
            slot="activator"
            :disabled="isDownloading"
            :loading="isDownloading"
            icon
          >
            <v-icon>far fa-fw fa-file-download</v-icon>
          </v-btn>

          <v-list>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon>far fa-fw fa-file</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>Attachments</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <v-divider />

          <v-card>
            <v-list>
              <v-list-tile v-for="(file, index) in files" :key="index">
                <v-list-tile-action>
                  <v-btn
                    slot="activator"
                    icon
                    @click="downloadFile(file.id, index)"
                  >
                    <v-icon>far fa-fw fa-download</v-icon>
                  </v-btn>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Download</v-list-tile-title>
                  <v-list-tile-sub-title>{{
                    file.fileName
                  }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </crud-header>
      <specific-annotation
        :article-ids="articleId"
        @image-deleted="imageDeleted"
      />
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import store from "@/store";
import { CLEAR } from "@/store/templates/crud/mutation-types";
import annotationModule from "@/store/modules/annotation";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { FETCH } from "../../store/templates/table/action-types";
import api from "@/api";
import SpecificAnnotation from "@/components/annotation/SpecificAnnotation";

const downloadFileCall = api.annotation.downloadFile;

export default {
  name: "article-annotation-dialog",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    SpecificAnnotation,
    CrudHeader
  },
  props: {
    createAnnotationDialog: {
      type: Boolean,
      default: false
    },
    articleId: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => {
    return {
      downloadCall: downloadFileCall,
      menu: false,
      isDownloading: false,
      files: [],
      isDeletable: true
    };
  },
  computed: {
    title() {
      return this.$store.getters["article/path"];
    },
    showDialog: {
      get() {
        return this.$store.getters["article/isAnnotationDialogOpen"];
      },
      set() {
        this.$store.commit("article/openAnnotationDialog", false);
        this.$emit("close");
      }
    },
    annotationId() {
      return this.$store.getters["article/annotationDialogId"];
    },
    hasFiles() {
      if (Array.isArray(this.files)) {
        return this.files.length > 0;
      } else {
        return false;
      }
    },
    deletable() {
      return !isNil(this.$store.getters["article/annotationDialogId"]);
    }
  },
  watch: {
    async showDialog(newVal) {
      if (newVal === true) {
        store.registerModule("annotation", annotationModule);
        if (!isNil(this.annotationId)) {
          await this.fetchAnnotation();
        }
        this.files = this.$store.getters["annotation/files"];
      } else {
        this.$store.commit("article/setAnnotationDialogId", null);
        this.$store.commit(`annotation/${CLEAR}`);
        store.unregisterModule("annotation");
      }
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit("article/openAnnotationDialog", false);
      this.$store.dispatch(`article/annotations/${FETCH}`);
    },
    imageDeleted() {
      this.$store.dispatch(`article/annotations/${FETCH}`);
    },
    async fetchAnnotation() {
      await this.$store.dispatch(`annotation/fetch`, { id: this.annotationId });
    },
    async downloadFile(fileId) {
      this.menu = false;
      this.isDownloading = true;
      await this.downloadCall(fileId);
      this.isDownloading = false;
    }
  }
};
</script>
