<template>
  <tr>
    <td>{{ website.host }}{{ article.path }}</td>
    <td>{{ visitors }}</td>
    <td>{{ round(bounceRate) }}%</td>
    <td>{{ time(timeOnPage) }}</td>
    <td>{{ round(rejectionRate) }}%</td>
    <td>{{ totalConversions }}</td>
    <td>{{ round(totalConversionRate) }}%</td>
    <td>{{ directConversions }}</td>
    <td>{{ round(directConversionRate) }}%</td>
    <td>{{ landingConversions }}</td>
    <td>{{ round(landingConversionRate) }}%</td>
    <td class="v-table-td--icon">
      <v-tooltip v-if="hasProblems" bottom>
        <v-icon slot="activator" color="warning">
          far fa-fw fa-exclamation-triangle
        </v-icon>
        <span>
          <span v-for="(problem, i) in problems" :key="i">
            {{ problem }}
          </span>
        </span>
      </v-tooltip>
      <v-icon v-else color="success">
        far fa-fw fa-check
      </v-icon>
    </td>
    <td v-if="hasRolesOrPermissions('article_sync')" class="v-table-td--icon">
      <v-btn icon @click="sync">
        <v-icon v-if="isStartingSync || article.isSyncing">
          far fa-fw fa-sync fa-spin
        </v-icon>
        <v-icon v-else-if="article.isSynced">
          far fa-fw fa-sync
        </v-icon>
        <v-icon v-else color="grey">
          far fa-fw fa-sync
        </v-icon>
      </v-btn>
    </td>
    <td v-if="hasRolesOrPermissions('article_view')" class="v-table-td--icon">
      <v-btn icon :to="{ name: 'articles.show', params: { articleId } }">
        <v-icon>far fa-fw fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapArticleGetters } = createNamespacedHelpers("articles");
const { mapGetters: mapLocaleGetters } = createNamespacedHelpers("locales");

export default {
  name: "articles-table-row",
  props: {
    articleId: {
      type: Number,
      required: true
    },
    timeframe: {
      type: String,
      default: "M"
    }
  },
  data: () => ({
    isStartingSync: false
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapArticleGetters({
      findArticleById: "findById",
      getWebsiteById: "getWebsiteById"
    }),
    ...mapLocaleGetters({
      findLocalesByIds: "findByIds"
    }),
    article() {
      return this.findArticleById(this.articleId);
    },
    website() {
      return this.getWebsiteById(this.article.website);
    },
    locales() {
      return this.findLocalesByIds(this.article.locales);
    },
    problems() {
      return [];
    },
    hasProblems() {
      return this.problems.length > 0;
    },
    timeframeMetric() {
      return this.$store.getters["articles/timeframeMetricById"](
        this.article.timeframeMetric
      );
    },
    visitors() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthVisitors;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekVisitors;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsVisitors;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearVisitors;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeVisitors;
      } else {
        return null;
      }
    },
    bounceRate() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthBounceRate;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekBounceRate;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsBounceRate;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearBounceRate;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeBounceRate;
      } else {
        return null;
      }
    },
    timeOnPage() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthTimeOnPage;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekTimeOnPage;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsTimeOnPage;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearTimeOnPage;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeTimeOnPage;
      } else {
        return null;
      }
    },
    rejectionRate() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthRejectionRate;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekRejectionRate;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsRejectionRate;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearRejectionRate;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeRejectionRate;
      } else {
        return null;
      }
    },
    totalConversions() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthTotalConversions;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekTotalConversions;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsTotalConversions;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearTotalConversions;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeTotalConversions;
      } else {
        return null;
      }
    },
    totalConversionRate() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthTotalConversionRate;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekTotalConversionRate;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsTotalConversionRate;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearTotalConversionRate;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeTotalConversionRate;
      } else {
        return null;
      }
    },
    directConversions() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthDirectConversions;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekDirectConversions;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsDirectConversions;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearDirectConversions;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeDirectConversions;
      } else {
        return null;
      }
    },
    directConversionRate() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthDirectConversionRate;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekDirectConversionRate;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsDirectConversionRate;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearDirectConversionRate;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeDirectConversionRate;
      } else {
        return null;
      }
    },
    landingConversions() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthLandingConversions;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekLandingConversions;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsLandingConversions;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearLandingConversions;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeLandingConversions;
      } else {
        return null;
      }
    },
    landingConversionRate() {
      if (this.timeframe === "M") {
        return this.timeframeMetric.lastMonthLandingConversionRate;
      } else if (this.timeframe === "W") {
        return this.timeframeMetric.lastWeekLandingConversionRate;
      } else if (this.timeframe === "3M") {
        return this.timeframeMetric.lastThreeMonthsLandingConversionRate;
      } else if (this.timeframe === "Y") {
        return this.timeframeMetric.lastYearLandingConversionRate;
      } else if (this.timeframe === "ALL") {
        return this.timeframeMetric.allTimeLandingConversionRate;
      } else {
        return null;
      }
    }
  },
  methods: {
    async sync() {
      this.isStartingSync = true;
      await this.$store.dispatch("articles/sync", {
        articleId: this.articleId
      });
      this.isStartingSync = false;
    },
    round(rate) {
      return rate.toFixed(2);
    },
    time(time) {
      let date = new Date(null);
      date.setSeconds(time.toString());
      return date.toISOString().substr(11, 8);
    }
  }
};
</script>
