<template>
  <v-chip
    :key="offerId"
    label
    small
    :color="offerStateContextClass"
    :dark="offerStateContextDark"
  >
    {{ company.name }}
  </v-chip>
</template>
<script type="text/babel">
import OfferMixin from "@/components/mixins/OfferMixin";

export default {
  name: "article-submission-table-row-lead-offer",
  mixins: [OfferMixin],
  props: {
    offerId: {
      type: Number,
      required: true
    }
  },
  computed: {
    offer() {
      return this.$store.state.article.submissions.offer[this.offerId];
    },
    company() {
      return this.$store.state.article.submissions.company[this.offer.company];
    }
  }
};
</script>
