<template>
  <div class="tw-mx-7">
    <div class="table-container">
      <div class="info-article">
        <v-sheet color="teal lighten-5">
          <div class="info-wrapper">
            <h2>
              {{ title }}
            </h2>
            <h3>
              <a :href="url" target="_blank">
                {{ url }}
              </a>
            </h3>
            <p>
              {{ description }}
            </p>
          </div>
        </v-sheet>
      </div>
      <router-link
        :to="
          `/backoffice/statistics/articles-conversions-report#filter%5Bfrom%5D=${from}&filter%5Bto%5D=${to}&filter%5Bwebsites%5D=${website.id}&perPage=20&sort=-total_conversions`
        "
        target="_blank"
        class="stat-link"
      >
        <v-sheet>
          <h2>Full Website Report</h2>
          <div class="icon-wrapper">
            <v-icon large class="stat-link-icon">fas fa-analytics</v-icon>
          </div>
        </v-sheet>
      </router-link>
    </div>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import moment from "moment";

const { mapGetters } = createNamespacedHelpers("article");

export default {
  name: "article-information",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["title", "website", "path", "description"]),
    url() {
      return `${this.website.protocol.toLowerCase()}://${this.website.host}${
        this.path
      }`;
    },
    to() {
      return moment().format("YYYY-MM-DD");
    },
    from() {
      return moment()
        .subtract(90, "days")
        .format("YYYY-MM-DD");
    }
  }
};
</script>
<style scoped>
.info-wrapper {
  padding: 15px;
}
h2 {
  color: #1a0dab;
}
a {
  color: #006621;
  text-decoration: none;
}
h3 {
  color: darkseagreen;
}
.table-container {
  display: table;
  width: 100%;
}
.info-article {
  display: table-cell;
  width: 90%;
  padding-right: 10px;
}
.stat-link {
  display: table-cell;
  width: 90%;
  margin-left: 20px;
  background-color: #e0f2f1;
  box-shadow: 3px 3px 14px -4px rgba(224, 242, 241, 1);
}
.stat-link:hover {
  background-color: #3c9696;
  box-shadow: 3px 3px 14px -4px rgba(60, 150, 150, 1);
}
.stat-link h2 {
  color: rgba(0, 0, 0, 0.54);
  text-align: center;
  font-size: 12px;
  background-color: #e0f2f1;
}
.stat-link:hover h2 {
  color: white;
  text-align: center;
  font-size: 12px;
  background-color: #3c9696;
}
.icon-wrapper {
  background-color: #e0f2f1;
}
.stat-link-icon {
  padding-top: 0.5%;
  position: absolute;
  left: 39%;
}
.stat-link:hover .stat-link-icon {
  color: white;
}
</style>
