import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import initialState from "./state";
import annotationsModule from "./annotations";
import submissionsModule from "./submissions";
import siblingsModule from "./siblings";
import highlanderArticlesModule from "./highlanderArticles";

export default {
  namespaced: true,
  state: () => initialState(),
  getters,
  actions,
  mutations,
  modules: {
    annotations: annotationsModule,
    submissions: submissionsModule,
    siblings: siblingsModule,
    highlanderArticles: highlanderArticlesModule
  }
};
