<template>
  <div>
    <v-card color="white">
      <crud-header
        :title="title"
        type="Article"
        store="article"
        icon="fal fa-fw fa-globe"
        :tabs="tabs"
      />
    </v-card>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import articleModule from "@/store/modules/article";
import { mapDetailViewLifecycleMethods } from "../helpers";

const { mapGetters } = createNamespacedHelpers("article");
const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "article",
  articleModule
);

export default {
  name: "article-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CrudHeader
  },

  computed: {
    ...mapGetters(["id", "title", "path"]),
    tabs() {
      return [
        {
          name: "Dashboard",
          to: { name: "articles.show", params: { articleId: this.id } },
          rolesOrPermissions: "article_view"
        },
        {
          name: "Submissions",
          to: { name: "articles.submissions", params: { articleId: this.id } },
          rolesOrPermissions: "article_submissions_view"
        },
        {
          name: "Research",
          to: { name: "articles.research", params: { articleId: this.id } },
          rolesOrPermissions: "article_research_view"
        },
        {
          name: "Settings",
          to: { name: "articles.settings", params: { articleId: this.id } },
          rolesOrPermissions: "article_settings_view"
        },
        {
          name: "Siblings",
          to: { name: "articles.siblings", params: { articleId: this.id } },
          rolesOrPermissions: "article_siblings_view"
        }
      ];
    }
  },
  ...detailViewLifecycleMethods
};
</script>
