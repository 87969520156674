<template>
  <v-form>
    <v-autocomplete-subject v-model="subjects" multiple />
    <v-select v-model="article" :items="types" label="Conversion" clearable />
  </v-form>
</template>
<script type="text/babel">
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputedFilters } = createNamespacedHelpers("article/submissions");

export default {
  name: "article-submission-table-filter",
  components: {
    VAutocompleteSubject
  },
  computed: {
    ...mapComputedFilters(["subjects", "article"]),
    types() {
      return [
        {
          text: "Direct",
          value: {
            type: "DIRECT",
            articleId: this.$store.state.article.model.id
          }
        },
        {
          text: "Landing",
          value: {
            type: "LANDING",
            articleId: this.$store.state.article.model.id
          }
        }
      ];
    }
  }
};
</script>
