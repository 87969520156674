<template>
  <transition-group name="fade" mode="out-in">
    <div v-show="!isLoadingStats" key="data">
      <v-layout>
        <v-flex style="margin-left: 24px; margin-right: 24px;" xs12>
          <article-device-total-conversion-rate-stats
            :stats="stats"
            :last-year-stats="lastYearStats"
            :average-data="averageData"
            :last-year-average-data="lastYearAverageData"
            :interval="interval"
          />
          <template v-for="(siblingId, index) in selectedSiblings">
            <article-device-total-conversion-rate-stats
              v-if="showSibling(siblingId)"
              :key="index"
              :title="
                `${siblingWebsite(articleSibling(siblingId).websiteId).domain}${
                  articleSibling(siblingId).path
                }`
              "
              :stats="siblingStats[siblingId].stats"
              :last-year-stats="siblingStats[siblingId].lastYearStats"
              :average-data="siblingStats[siblingId].averageData"
              :last-year-average-data="
                siblingStats[siblingId].lastYearAverageData
              "
              :interval="interval"
            />
          </template>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex style="margin-left: 24px; margin-right: 24px;" xs12>
          <article-device-traffic-graph
            :stats="stats"
            :last-year-stats="lastYearStats"
            :average-data="averageData"
            :last-year-average-data="lastYearAverageData"
            :interval="interval"
          />
          <template v-for="(siblingId, index) in selectedSiblings">
            <article-device-traffic-graph
              v-if="showSibling(siblingId)"
              :key="index"
              :title="
                `${siblingWebsite(articleSibling(siblingId).websiteId).domain}${
                  articleSibling(siblingId).path
                }`
              "
              :stats="siblingStats[siblingId].stats"
              :last-year-stats="siblingStats[siblingId].lastYearStats"
              :average-data="siblingStats[siblingId].averageData"
              :last-year-average-data="
                siblingStats[siblingId].lastYearAverageData
              "
              :interval="interval"
            />
          </template>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex style="margin-left: 24px; margin-right: 24px;" xs12>
          <article-device-total-conversions-stats
            :stats="stats"
            :last-year-stats="lastYearStats"
            :average-data="averageData"
            :last-year-average-data="lastYearAverageData"
            :interval="interval"
          />
          <template v-for="(siblingId, index) in selectedSiblings">
            <article-device-total-conversions-stats
              v-if="showSibling(siblingId)"
              :key="index"
              :title="
                `${siblingWebsite(articleSibling(siblingId).websiteId).domain}${
                  articleSibling(siblingId).path
                }`
              "
              :stats="siblingStats[siblingId].stats"
              :last-year-stats="siblingStats[siblingId].lastYearStats"
              :average-data="siblingStats[siblingId].averageData"
              :last-year-average-data="
                siblingStats[siblingId].lastYearAverageData
              "
              :interval="interval"
            />
          </template>
        </v-flex>
      </v-layout>
    </div>
    <div
      key="loading"
      v-if="isLoadingStats"
      class="tw-grid tw-grid-cols-1 tw-col-span-full tw-content-end tw-justify-center tw-h-32 tw-m-6 tw-p-0"
    >
      <h2 class="tw-flex tw-justify-center tw-align-center loading-info">
        ...Statistics are loading
      </h2>
      <v-progress-linear
        class="tw-m-0"
        :indeterminate="true"
        color="secondary"
      />
    </div>
  </transition-group>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import Vue from "vue";
import moment from "moment";
import { isNil } from "lodash";
import api from "@/api";
import ArticleDeviceTotalConversionRateStats from "../../components/article/dashboard/stats/ArticleDeviceTotalConversionRate";
import ArticleDeviceTotalConversionsStats from "../../components/article/dashboard/stats/ArticleDeviceTotalConversions";
import ArticleDeviceTrafficGraph from "../../components/article/dashboard/stats/ArticleDeviceTrafficGraph";

const { mapGetters } = createNamespacedHelpers("article");

const fetchCall = api.article.stats.fetchDeviceTimeSeries;
const fetchCallAverages = api.article.stats.fetchAverages;

export default {
  name: "article-device-stats-view",
  components: {
    ArticleDeviceTrafficGraph,
    ArticleDeviceTotalConversionsStats,
    ArticleDeviceTotalConversionRateStats
  },
  props: {
    selectedSiblings: {
      type: Array,
      default: () => []
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoadingStats: false,
      stats: [],
      lastYearStats: [],
      averageData: {},
      lastYearAverageData: {},
      siblingStats: []
    };
  },
  computed: {
    ...mapGetters(["article", "type"]),
    annotations() {
      return this.article ? this.article.annotations : [];
    },
    now() {
      return moment().format("DD-MM-YYYY");
    },
    oneYearAgo() {
      return moment()
        .subtract(1, "years")
        .format("DD-MM-YYYY");
    },
    siblings() {
      return this.$store.getters["article/articleSiblings"];
    }
  },
  beforeMount() {
    const to = moment().format("YYYY-MM-DD");
    const from = moment()
      .subtract(1, "years")
      .startOf("month")
      .format("YYYY-MM-DD");

    const lastYearTo = moment()
      .subtract(1, "years")
      .endOf("month")
      .format("YYYY-MM-DD");
    const lastYearFrom = moment()
      .subtract(2, "years")
      .startOf("month")
      .format("YYYY-MM-DD");
    this.fetch(from, to, lastYearFrom, lastYearTo);
  },
  watch: {
    interval(newVal) {
      if (newVal === "monthly") {
        const to = moment().format("YYYY-MM-DD");
        const from = moment()
          .subtract(1, "years")
          .startOf("month")
          .format("YYYY-MM-DD");

        const lastYearTo = moment()
          .subtract(1, "years")
          .endOf("month")
          .format("YYYY-MM-DD");
        const lastYearFrom = moment()
          .subtract(2, "years")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.fetch(from, to, lastYearFrom, lastYearTo);
        if (this.selectedSiblings.length > 0) {
          this.fetchSiblingStats(
            this.selectedSiblings,
            from,
            to,
            lastYearFrom,
            lastYearTo
          );
        }
      } else {
        const to = moment().format("YYYY-MM-DD");
        const from = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");

        const lastYearTo = moment()
          .subtract(1, "years")
          .endOf("month")
          .format("YYYY-MM-DD");
        const lastYearFrom = moment()
          .subtract(1, "years")
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.fetch(from, to, lastYearFrom, lastYearTo);
        if (this.selectedSiblings.length > 0) {
          this.fetchSiblingStats(
            this.selectedSiblings,
            from,
            to,
            lastYearFrom,
            lastYearTo
          );
        }
      }
    },
    fab(val) {
      this.tooltips = false;
      this.tooltipsDisabled = false;
      val &&
        setTimeout(() => {
          this.tooltips = true;
          this.$nextTick(() => (this.tooltipsDisabled = true));
        }, 100);
    },
    selectedSiblings(newVal, oldVal) {
      let difference = newVal.filter(x => !oldVal.includes(x))[0];

      if (!isNil(difference)) {
        if (newVal === "monthly") {
          const to = moment().format("YYYY-MM-DD");
          const from = moment()
            .subtract(1, "years")
            .startOf("month")
            .format("YYYY-MM-DD");

          const lastYearTo = moment()
            .subtract(1, "years")
            .endOf("month")
            .format("YYYY-MM-DD");
          const lastYearFrom = moment()
            .subtract(2, "years")
            .startOf("month")
            .format("YYYY-MM-DD");
          if (this.selectedSiblings.length > 0) {
            this.fetchSiblingStats(
              this.selectedSiblings,
              from,
              to,
              lastYearFrom,
              lastYearTo
            );
          }
        } else {
          const to = moment().format("YYYY-MM-DD");
          const from = moment()
            .subtract(3, "months")
            .startOf("month")
            .format("YYYY-MM-DD");

          const lastYearTo = moment()
            .subtract(1, "years")
            .endOf("month")
            .format("YYYY-MM-DD");
          const lastYearFrom = moment()
            .subtract(1, "years")
            .subtract(3, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
          if (this.selectedSiblings.length > 0) {
            this.fetchSiblingStats(
              this.selectedSiblings,
              from,
              to,
              lastYearFrom,
              lastYearTo
            );
          }
        }
      }
    }
  },
  methods: {
    createAnnotationDialog() {
      this.$store.commit("article/openAnnotationDialog", true);
    },
    articleSibling(id) {
      return isNil(id) ? "" : this.$store.getters["article/sibling"](id);
    },
    siblingWebsite(id) {
      return this.$store.getters["article/siblingWebsite"](id);
    },
    async fetch(from, to, lastYearFrom, lastYearTo) {
      this.isLoadingStats = true;
      this.stats = await fetchCall(this.$store.state.article.model.id, {
        date: { from: from, to: to },
        interval: this.interval
      });
      this.lastYearStats = await fetchCall(this.$store.state.article.model.id, {
        date: { from: lastYearFrom, to: lastYearTo },
        interval: this.interval
      });
      this.averageData = await fetchCallAverages(
        this.$store.state.article.model.id,
        {
          date: { from: from, to: to },
          interval: this.interval
        }
      );
      this.lastYearAverageData = await fetchCallAverages(
        this.$store.state.article.model.id,
        {
          date: { from: lastYearFrom, to: lastYearTo },
          interval: this.interval
        }
      );
      this.isLoadingStats = false;
    },
    async fetchSiblingStats(articleId, from, to, lastYearFrom, lastYearTo) {
      this.isLoadingStats = true;
      Vue.set(this.siblingStats, articleId, {});
      Vue.set(this.siblingStats[articleId], "stats", []);
      Vue.set(this.siblingStats[articleId], "lastYearStats", []);
      Vue.set(this.siblingStats[articleId], "averageData", {});
      Vue.set(this.siblingStats[articleId], "lastYearAverageData", {});

      const stats = await fetchCall(articleId, {
        date: { from: from, to: to },
        interval: this.interval
      });
      const lastYearStats = await fetchCall(articleId, {
        date: { from: lastYearFrom, to: lastYearTo },
        interval: this.interval
      });
      const averageData = await fetchCallAverages(articleId, {
        date: { from: from, to: to },
        interval: this.interval
      });
      const lastYearAverageData = await fetchCallAverages(articleId, {
        date: { from: lastYearFrom, to: lastYearTo },
        interval: this.interval
      });
      Vue.set(this.siblingStats, articleId, {});
      Vue.set(this.siblingStats[articleId], "stats", stats);
      Vue.set(this.siblingStats[articleId], "lastYearStats", lastYearStats);
      Vue.set(this.siblingStats[articleId], "averageData", averageData);
      Vue.set(
        this.siblingStats[articleId],
        "lastYearAverageData",
        lastYearAverageData
      );
      this.isLoadingStats = false;
    },
    showSibling(id) {
      if (!isNil(this.siblingStats[id])) {
        return !isNil(this.siblingStats[id].stats);
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped>
.date {
  text-align: center;
  color: darkgray;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
