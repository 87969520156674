<template>
  <v-autocomplete
    v-model="model"
    v-validate="internalValidationRules"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="label"
    :error-messages="internalErrorMessages"
    :items="items"
    :label="internalLabel"
    :item-text="itemText"
    :item-value="itemValue"
    :readonly="isReadOnly"
    :disabled="isDisabled"
    :multiple="isMultiple"
    :clearable="clearable"
    :loading="isLoading"
    :search-input.sync="search"
    :chips="isMultiple"
    persistent-hint
    :browser-autocomplete="browserAutocomplete"
    @change="onChange"
  >
    <template v-slot:selection="{ item, selected }" v-if="isMultiple">
      <v-chip :selected="selected" small label>
        <span v-text="item.name"></span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script type="text/babel">
import VAutocompleteMixin from "@/components/mixins/input/VAutocompleteMixin";
import { SET_FILTER } from "@/store/templates/select/mutation-types";
import { INIT } from "@/store/templates/select/action-types";

export default {
  name: "v-autocomplete-tag-sub-type",
  inject: ["$validator"],
  mixins: [VAutocompleteMixin],
  props: {
    type: {
      type: [String, Number],
      required: false
    }
  },
  data: () => ({
    store: "tag-sub-type-select",
    defaultLabel: "Tag sub type"
  }),
  watch: {
    type(newVal) {
      this.$store.commit(`tag-sub-type-select/${SET_FILTER}`, { type: newVal });
      this.getData({ type: newVal });
    }
  },
  methods: {
    getData(filter) {
      this.$store.dispatch(`tag-sub-type-select/${INIT}`, filter);
    }
  }
};
</script>
