import crudGetters from "../../templates/crud/getters";
import { isNil } from "lodash";

export default {
  article: state => state.model,
  id: state => state.model.id,
  path: state => state.model.path,
  title: state => state.model.title,
  description: state => state.model.description,
  remark: state => state.model.remark,
  locales: state => state.model.locales,
  subjects: state => state.model.subjects,
  researchLink: state => state.model.researchLink,
  researchLinkEditable: state => state.model.researchLinkEditable,
  website: state => state.website[state.model.website],
  getWebsiteById: state => websiteId => state.website[websiteId],
  timeframeMetric: state => state.timeframeMetric[state.model.timeframeMetric],
  pageTypeMetric: state => state.pageTypeMetric[state.model.pageTypeMetric],
  isAnnotationDialogOpen: state => state.isAnnotationDialogOpen,
  annotationDialogId: state => state.annotationDialogId,
  type: state => state.model.type,
  tags: state => state.model.tags,
  isSearchable: state => state.model.isSearchable,
  siblings: state =>
    state.model.siblings.map(siblingId => state.sibling[siblingId]),
  sibling: state => id => state.article[id],
  articleSiblings: state => {
    const cluster = state.articleCluster[state.model.cluster];
    if (isNil(cluster)) {
      return [];
    }
    let siblings = cluster.articleSiblings.map(
      sibling => state.articleSibling[sibling]
    );
    siblings = siblings.filter(sibling => sibling.article !== state.model.id);
    return siblings;
  },
  siblingWebsite: state => id => state.website[id],
  ...crudGetters
};
