<template>
  <v-container fluid>
    <v-text-field
      label="Research link"
      v-model="researchLink"
      v-validate="'max:255'"
      :counter="255"
      data-vv-name="researchLink"
      :error-messages="errors.collect('researchLink')"
    />
    <v-text-field
      label="Research link editable"
      v-model="researchLinkEditable"
      v-validate="'max:255'"
      :counter="255"
      data-vv-name="researchLinkEditable"
      :error-messages="errors.collect('researchLinkEditable')"
    />
    <v-divider class="my-4" />
    <v-switch
      label="Is searchable on Buldit.be (if the website is not searchable, neither is the article)"
      v-model="isSearchable"
    />
  </v-container>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputed } = createNamespacedHelpers("article");

export default {
  name: "article-settings",
  inject: ["$validator"],
  computed: {
    ...mapComputed(["researchLink", "researchLinkEditable", "isSearchable"])
  }
};
</script>
